import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { container } from './akcja-cytrynowka-closed-page.module.scss';
import { IPage } from '../models/page.model';

import AkcjaCytrynowkaLayout from "../layouts/akcja-cytrynowka-layout";

interface IAkcjaCytrynowkaClosedPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const AkcjaCytrynowkaClosedPage: React.FC<IAkcjaCytrynowkaClosedPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;

    return (
        <AkcjaCytrynowkaLayout
            page={page}
            className={container}
            headerProps={{
                titleFirstLine: t('akcja.cytrynowka.closed.title.1'),
                titleSecondLine: t('akcja.cytrynowka.closed.title.2'),
            }}
        >
        </AkcjaCytrynowkaLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default AkcjaCytrynowkaClosedPage;
